import _tslib from "tslib";
import _react from "react";
import _reactStyleSingleton from "react-style-singleton";
import _utils from "./utils";
import _constants from "./constants";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var tslib_1 = _tslib;
var React = _react;
var react_style_singleton_1 = _reactStyleSingleton;
var utils_1 = _utils;
var constants_1 = _constants;
;
var Style = react_style_singleton_1.styleSinglentone();

var getStyles = function (_a, allowRelative, gapMode, important) {
  var left = _a.left,
      top = _a.top,
      right = _a.right,
      gap = _a.gap;

  if (gapMode === void 0) {
    gapMode = "margin";
  }

  return "\n  ." + constants_1.noScrollbarsClassName + " {\n   overflow: hidden " + important + ";\n   padding-right: " + gap + "px " + important + ";\n  }\n  body {\n    overflow: hidden " + important + ";\n    " + [allowRelative && "position: relative " + important + ";", gapMode === "margin" && "\n    padding-left: " + left + "px;\n    padding-top: " + top + "px;\n    padding-right: " + right + "px;\n    margin-left:0;\n    margin-top:0;\n    margin-right: " + gap + "px " + important + ";\n    ", gapMode === "padding" && "padding-right: " + gap + "px " + important + ";"].filter(Boolean).join("") + "\n  }\n  \n  ." + constants_1.zeroRightClassName + " {\n    right: " + gap + "px " + important + ";\n  }\n  \n  ." + constants_1.fullWidthClassName + " {\n    margin-right: " + gap + "px " + important + ";\n  }\n  \n  ." + constants_1.zeroRightClassName + " ." + constants_1.zeroRightClassName + " {\n    right: 0 " + important + ";\n  }\n  \n  ." + constants_1.fullWidthClassName + " ." + constants_1.fullWidthClassName + " {\n    margin-right: 0 " + important + ";\n  }\n";
};

var RemoveScrollBar = function (_super) {
  tslib_1.__extends(RemoveScrollBar, _super);

  function RemoveScrollBar() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.state = {
      gap: utils_1.getGapWidth(_this.props.gapMode)
    };

    _this.onResize = function () {
      _this.forceUpdate();

      if (_this.state.gap && _this.props.dynamic) {
        if (window.innerHeight > document.body.offsetHeight) {
          _this.setGap(utils_1.zeroGap);
        }
      }
    };

    return _this;
  }

  RemoveScrollBar.prototype.componentDidMount = function () {
    var gap = utils_1.getGapWidth(this.props.gapMode);

    if (gap !== this.state.gap) {
      this.setGap(gap);
    }

    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.onResize);
    }
  };

  RemoveScrollBar.prototype.componentWillUnmount = function () {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize);
    }
  };

  RemoveScrollBar.prototype.componentDidUpdate = function () {
    if (!this.state.gap) {
      var gap = utils_1.getGapWidth(this.props.gapMode);

      if (gap !== this.state.gap) {
        this.setGap(gap);
      }
    }
  };

  RemoveScrollBar.prototype.setGap = function (gap) {
    this.setState({
      gap: gap
    });
  };

  RemoveScrollBar.prototype.render = function () {
    var _a = this.props,
        noRelative = _a.noRelative,
        noImportant = _a.noImportant,
        _b = _a.gapMode,
        gapMode = _b === void 0 ? "margin" : _b;
    var gap = this.state.gap;
    return React.createElement(Style, {
      styles: getStyles(gap, !noRelative, gapMode, !noImportant ? "!important" : "")
    });
  };

  return RemoveScrollBar;
}(React.Component);

exports.RemoveScrollBar = RemoveScrollBar;
export default exports;